import React, { useLayoutEffect, useRef } from "react";
import { staticAppConfig } from "../AppData/Recoil";

export const OneXPageTemplate = ({ showDebug, debugComponent, children }) => {

  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      try {
        window.oneX.removeElement(currentRef); 
      } catch (error) {
      }
    }
  });

  return (
    <div
      ref={wrapperRef}
    >
      <header data-type="1x-header-unauth" data-limited="true"></header>
      <div className="-oneX-container" style={{display:"flex", justifyContent: "center"}}>{children}</div>
      <footer data-type="1x-footer" data-limited="true"></footer>
      {showDebug ? debugComponent : null}
    </div>
  );
};

const OneXPageContainer = ({ children }) => {

  return (
    <OneXPageTemplate showDebug={staticAppConfig.debugMode} debugComponent={<pre>{JSON.stringify({}, null, 2)}</pre>}>
      {children}
    </OneXPageTemplate>
  );
};

export default OneXPageContainer;