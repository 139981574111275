import axios from "axios";

export const axiosInstance = axios.create({
  responseType: "json",
  timeout: 24000,
  headers: {
    "Content-Type": "application/vnd.api+json",
    "Accept": "application/vnd.api+json"
  },
});

export function registerAxiosInterceptor() {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}
