import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

export const ICON_CLASSES = {
  SUCCESS: "success_fill_32",
  SUCCESS_24: "success_fill_24",
  ERROR: "alert_fill_32",
  ERROR_24: "alert_fill_24",
  AUTO: "auto_insurance_32",
  AUTO_24: "auto_insurance_24"
};

const OneXIcon = ({
  id,
  type = "SUCCESS",
  bounds = "64px"
}) => {

  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }
  });

  return (
    <div tabIndex="0" id={id} className="-oneX-icon-container" ref={wrapperRef} data-testid={"oneXIcon-wrapper"}>
      <div className="-oneX-icon" data-icon={ICON_CLASSES[type]} style={{ height: bounds, width: bounds }}> </div>
    </div>
  )
};

OneXIcon.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["SUCCESS", "ERROR", "AUTO"]),
  size: PropTypes.string,
};


export default OneXIcon;