import "./App.css";
import "./OneX/OneXButton";
import ApplicationContainer from "./ApplicationContainer";
// Non-functional change to trigger main.js generation.
function App() {
  return (
    <div id="root" className="-oneX" data-testid="application-wrapper">
      <ApplicationContainer />
    </div>
  );
}
export default App;
