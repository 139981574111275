const validateRequiredField = (value) => {
    if (value && value.length >= 1) {
      return -1;
    } else {
      return 0;
    }
  };
  
  const validateTelephoneNumber = (value) => {
    if (value && value.replace(/\D/g, "").length === 10) {
      return -1;
    } else {
      return 0;
    }
  };
  
  const validateDateOfBirthField = (value) => {

    let dateOfBirth = null;
    let month = null;
    let day = null;
    let year = null;
    let currentYear = new Date().getFullYear();
    
      month = value.substr(0, 2);
      day = value.substr(3, 2);
      year = value.substr(6, 4);
  
    dateOfBirth = `${month}-${day}-${year}`;

    if(value && value.length === 10 && value === dateOfBirth && month <= 12 && day <= 31 && year < currentYear && dateOfBirth !== "00-00-0000"){
      return -1;
    }
    else {
      return 0;
    }
  };
  
const validateAgeRequirement = (value) => {

  var today = new Date();
  var birthDate = new Date(value);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  if (age < 13) {
    return 501;
  } else {
    return -1;
  }

}

const FormValidator = {
  validateRequiredField: validateRequiredField,
  validateDateOfBirthField: validateDateOfBirthField,
  validateTelephoneNumber: validateTelephoneNumber,
  validateAgeRequirement: validateAgeRequirement
};

export default FormValidator;