import React from "react";

const Flex = ({
  className,
  container,
  justifyContent,
  flexDirection,
  flexGrow,
  flexBasis,
  flexShrink,
  flexWrap,
  flex,
  alignItems,
  margin,
  padding,
  width,
  height,
  maxWidth,
  children,
  style
}) => (
  <div
    className={className}
    style={{
      display: container ? "flex" : "block",
      justifyContent: justifyContent,
      flexDirection: flexDirection,
      flexGrow: flexGrow,
      flexBasis: flexBasis,
      flexShrink: flexShrink,
      flexWrap: flexWrap,
      flex: flex,
      alignItems: alignItems,
      margin: margin,
      padding: padding,
      width: width,
      height: height,
      maxWidth: maxWidth,
      ...style
    }}
  >
    {children}
  </div>
);

export default Flex;
