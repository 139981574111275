function pushScreenChangeToAnalytics(screenId) {
  if (typeof dataLayer === "undefined") {
    window.dataLayer = {
      screenId: screenId,
      screenChannel: "view-auto-id-cards",
    };
  } else {
    window.dataLayer.screenId = screenId;
    window.dataLayer.screenChannel = "view-auto-id-cards";
  }

  let event;
  if (typeof Event === "function") {
    event = new Event("screenChange", { bubbles: true, cancelable: false });
  } else {
    // Else block added to fix event creation for IE9+
    event = document.createEvent("Event");
    event.initEvent("screenChange", true, false);
  }
  document.dispatchEvent(event);
}

const EnsightenAnalytics = {
  pushScreenChangeToAnalytics: pushScreenChangeToAnalytics,
};

export default EnsightenAnalytics;
