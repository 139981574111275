import React, { useLayoutEffect, useRef } from "react";

const OneXContextualHelp = ({
  id,
  message="",
  small=false,
  standAlone=true,
  inline=false,
  helpFor=""
}) => {

  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }

  }, []);

  return(
    <span ref={wrapperRef} id={id} className={"-oneX-contextual-help-container"+(standAlone ? ` -oneX-standalone-help` : "")} style={inline ? {display: "inline"} : null}>
      <button type="button" className="-oneX-btn-control -oneX-contextual-help" aria-label={`help for ${helpFor}`} aria-controls={"helpContainer"+id} aria-expanded="false" style={inline ? { padding: "0px", paddingTop: "10px", width: "33px"} : null}>
        <div className={`-oneX-icon--controls--question ${(standAlone && small) ? "-onex-standalone-help-button-16px": ""}`} role="img" aria-label={"Question mark image"} style={inline ? { padding: "0px", paddingTop: "10px" } : null}></div>
        <div className="-oneX-notch"></div>
      </button>
      <span className="-oneX-contextual-help--text" id={"helpContainer"+id}>
        <div> {message} </div>
        <button type="button" aria-label="Close Help" className="-oneX-close-X-button">
          <span className="-oneX-icon--close"></span>
        </button>
      </span>
    </span>
  )
}

export default OneXContextualHelp;