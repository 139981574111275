import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";


const oneXClipTextClasses = {
  clip: "-oneX-loading-message -oneX-clipped",
  noClip: "-oneX-loading-message",
};

const OneXPageLoader = ({ children, clipText }) => {

  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }
  }, []);

  return (
    <div className="-oneX" ref={wrapperRef}>
      <div className="-oneX-page-loader" tabIndex="-1" style={{margin: "auto", width: "100%"}}>
        <h2 className={clipText ? oneXClipTextClasses.clip : oneXClipTextClasses.noClip}>{children}</h2>
        <div className="-oneX-loader-icon" aria-hidden="true"></div>

        <div className="-oneX-loaderdiv" aria-hidden="true">
          <svg className="-oneX-small">
            <circle cx="32" cy="32" r="28.8" viewBox="0 0 64 64"></circle>
          </svg>

          <svg className="-oneX-medium">
            <circle cx="40" cy="40" r="36" viewBox="0 0 80 80"></circle>
          </svg>

          <svg className="-oneX-large">
            <circle cx="50" cy="50" r="45" viewBox="0 0 100 100"></circle>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default OneXPageLoader;

OneXPageLoader.defaultProps = {
  children: "Loading...",
  clipText: false,
};

OneXPageLoader.propTypes = {
  children: PropTypes.string.isRequired,
  clipText: PropTypes.bool,
};