export const adaptPhoneNumber = phoneNumber => {
    let adaptedPhone = null;
  
    adaptedPhone = phoneNumber.replace(/\D/g, "");

    // DR Logic to remove a leading 1 from phone number
    if (adaptedPhone.length > 10 && adaptedPhone.substr(0,1) === "1") {
        adaptedPhone = adaptedPhone.substr(1,10);
    }

    return adaptedPhone;
  };
  
  export const adaptDateOfBirth = dateOfBirth => {
    let adaptedDateOfBirth = null;
    let month = null;
    let day = null;
    let year = null;
    
    if (dateOfBirth) {
      month = dateOfBirth.substr(0, 2);
      day = dateOfBirth.substr(3, 2);
      year = dateOfBirth.substr(6, 4);
    }
  
    adaptedDateOfBirth = `${year}-${month}-${day}`;
  
    return adaptedDateOfBirth;
  };
  