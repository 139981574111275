const DEVICE_MEDIA_QUERIES = {
    XS: "only screen and (max-width : 575px)",
    S: "only screen and (min-width : 576px) and (max-width : 767px)",
    M: "only screen and (min-width : 768px) and (max-width : 991px)",
    L: "only screen and (min-width : 992px) and (max-width : 1199px)",
    XL: "only screen and (min-width : 1200px)",
    MOBILE: "only screen and (max-width : 767px)",
    TABLET: "only screen and (min-width : 768px) and (max-width : 991px)",
    DESKTOP: "only screen and (min-width : 992px)"
}

const OneXSpecs = {
    DEVICE_MEDIA_QUERIES: DEVICE_MEDIA_QUERIES
}

export default OneXSpecs;