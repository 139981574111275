import { atom } from "recoil";

export const telephoneNumberState = atom({ key: "telephoneNumberState", default: { value: null, validationCode: -1 } });
export const birthdateState = atom({ key: "birthdateState", default: { value: null, validationCode: -1 } });
export const emailState = atom({ key: "emailState", default: null });
export const hasAccountState = atom({ key: "hasAccount", default: false });

export const pageLevelErrorState = atom({ key: "pageLevelErrorState", default: -1 });
export const emailSentState = atom({ key: "emailSentState", default: false });
export const waitingForResponseState = atom({ key: "waitingForResponseState", default: false });
export const submitLimitState = atom({ key: "submitLimnitState", default: 5 });
export const disableButtonState = atom({ key: "disableButtonState", default: false });
export const tooManyClientsState = atom({ key: "tooManyClientsState", default: false });
export const noClientState = atom({ key: "noClientState", default: false });
export const successfulMatchState = atom({ key: "successfulMatchState", default: false});
export const jwtState = atom({key: "jwt", default: null});

export const staticAppConfig = {
  debugMode: window.location.href.indexOf("debug=true") > -1,
  routes: {
    landing: `${process.env.PUBLIC_URL}/view-insurance-card`,
    cardsFound: `${process.env.PUBLIC_URL}/cards-found`,
    noCards: `${process.env.PUBLIC_URL}/documents-unavailable`,
    login: `${process.env.PUBLIC_URL}/login-required`,
    success: `${process.env.PUBLIC_URL}/success`,
    error: `${process.env.PUBLIC_URL}/error`,
    shapeError: `${process.env.PUBLIC_URL}/technical-error`
  },
};
