import React, { useState, useEffect } from "react";
import OneXButton from "../OneX/OneXButton";
import { useNavigate } from "react-router-dom";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import { useMediaQuery } from "@uidotdev/usehooks";
import OneXSpecs from "../OneX/OneXSpecs";

const DocUnavailable = () => {

  const [init, setInit] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.MOBILE);
  const isTablet = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.TABLET);
  const isDesktop = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.DESKTOP);

  useEffect(() => {
    if (!init) {
      EnsightenAnalytics.pushScreenChangeToAnalytics("docsunavailable");
      document.title = "Get insurance ID card - Document Unavailable";
      setInit(true);
    }
  }, [init, navigate]);

  const buttonHandler = () => {
    //window.location.href = "https://www.statefarm.com/";
    window.location.href = "https://proofing.statefarm.com/login-interceptor";
  }

  return <div className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}>
    <h1 style={{ marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>
      Sorry, there was an issue processing your request
    </h1>
    <div className={"-oneX-body--intro"} style={{ marginBottom: "48px", marginTop: "48px" }}>
      If you need immediate access to your card, please log in or contact your agent.
    </div>
    <OneXButton
      id="RtH_Btn"
      type={"primary"}
      onMouseDown={buttonHandler}
      clickHandler={() => {
        window.location.href = "https://proofing.statefarm.com/login-interceptor";
      }}
    >
      Log in
    </OneXButton>
  </div>;
};

export default DocUnavailable;
