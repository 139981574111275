import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";


const oneXTypeClasses = {
  informational: {
    section: "-oneX-notification__icon -oneX-notification--informational",
    span: "-oneX-icon--informational",
  },
  warning: {
    section: "-oneX-notification__icon -oneX-notification--warning",
    span: "-oneX-icon--warning",
  },
  error: {
    section: "-oneX-notification__icon -oneX-notification--error",
    span: "-oneX-icon--error",
  },
  confirmation: {
    section: "-oneX-notification__icon -oneX-notification--confirmation",
    span: "-oneX-icon--confirmation",
  },
}

const OneXPageLevelNotification = ({ id, children, type }) => {

  const wrapperRef = useRef(null);

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }
  }, []);

  return (
    <div id={"IA_" + id} ref={wrapperRef} className="-oneX -oneX-notification" tabIndex="-1" aria-live="assertive">
      <section className={oneXTypeClasses[type].section}>
        <span
          className={oneXTypeClasses[type].span}
          aria-label={type}
          role='img'>
        </span>
      </section>
      <section className="-oneX-notification__text">
        {children}
      </section>
    </div>
  );
};

OneXPageLevelNotification.defaultProps = {
  type: "informational",
};

OneXPageLevelNotification.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(oneXTypeClasses)),
};

export default OneXPageLevelNotification;