import React, { useEffect, useState } from "react";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import OneXButton from "../OneX/OneXButton";
import {
  staticAppConfig,
  successfulMatchState
} from "../AppData/Recoil";
import { useNavigate, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useMediaQuery } from "@uidotdev/usehooks";
import OneXSpecs from "../OneX/OneXSpecs";

const LoginRequired = () => {
  const [init, setInit] = useState(false);
  const successfulMatch = useRecoilValue(successfulMatchState);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.MOBILE);
  const isTablet = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.TABLET);
  const isDesktop = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.DESKTOP);

  useEffect(() => {
    if (!init) {
      EnsightenAnalytics.pushScreenChangeToAnalytics("loginrequired");
      setInit(true);
    }
  }, [init, navigate]);

  return !successfulMatch ? (<Navigate to={staticAppConfig.routes.landing} />) : (
    <div
      className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}
      style={{
        marginBottom: "128px"
      }}>
      <h1 style={{ marginBottom: "28px", marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>
        Login Required
      </h1>
      <div className={"-oneX-body--intro"} style={{ marginBottom: "38px", marginLeft: "8px" }}>
        To get insurance cards without logging in, you must have a valid email address on file.
      </div>
      <OneXButton
        id="RtH_Btn"
        type={"primary"}
        clickHandler={() => {
          window.location.href = "https://www.statefarm.com/customer-care/manage-your-accounts";
        }}
        size={"large"}
      >
        Log In
      </OneXButton>
    </div>
  );
};

export default LoginRequired;
