import { axiosInstance } from "./AxiosConfig";
import * as Adapter from "./Adapter";
import { staticAppConfig } from "../AppData/Recoil";
import FormValidator from "./FormValidator";
import EnsightenAnalytics from "./EnsightenAnalytics";

const getProdMatchEndpoint = () => {

  console.log(window.location.hostname)

  switch (window.location.hostname) {

    case 'get-id-card.delitess.c1.statefarm':
      return "https://api.get-id-card.delitess.c1.statefarm/match";

    case 'get-id-card.delitess.test.c1.statefarm':
      return "https://api.get-id-card.delitess.test.c1.statefarm/match";

    case 'get-id-card-dev-1.delitess.test.c1.statefarm':
      return "https://api.get-id-card.delitess.test.c1.statefarm/match";

    case 'localhost':
      return "http://localhost:3001/match"

    default:
      return "https://api.get-id-card.delitess.c1.statefarm/match";

  }

}

const getProdSelectEndpoint = () => {

  switch (window.location.hostname) {

    case 'get-id-card.delitess.c1.statefarm':
      return "https://api.get-id-card.delitess.c1.statefarm/selection";

    case 'get-id-card.delitess.test.c1.statefarm':
      return "https://api.get-id-card.delitess.test.c1.statefarm/selection";

    case 'get-id-card-dev-1.delitess.test.c1.statefarm':
      return "https://api.get-id-card.delitess.test.c1.statefarm/selection";

    case 'localhost':
      return "http://localhost:3001/selection"

    default:
      return "https://api.get-id-card.delitess.c1.statefarm/selection";

  }

}

export const postMatch = (
  telephoneNumber,
  birthdate,
  setNoClient,
  setTooManyClients,
  setWaitingForResponse,
  setPageLevelError,
  setSuccessfulMatch,
  responseHandler,
  navigate
) => {
  setNoClient(false);
  setTooManyClients(false);
  setWaitingForResponse(true);

  let telephoneNumberCheck = FormValidator.validateTelephoneNumber(telephoneNumber);
  let birthdateCheck = FormValidator.validateDateOfBirthField(birthdate);
  let ageRequirementCheck = FormValidator.validateAgeRequirement(birthdate);

  if (ageRequirementCheck === 501) {
    setPageLevelError(501);
    setWaitingForResponse(false);
  } else if (telephoneNumberCheck === -1 && birthdateCheck === -1) {
    let requestData = {
      phoneNumber: Adapter.adaptPhoneNumber(telephoneNumber),
      dateOfBirth: Adapter.adaptDateOfBirth(birthdate),
    };

    axiosInstance({
      method: "post",
      url: getProdMatchEndpoint(),
      data: {
        data: {
          telephoneNumber: requestData.phoneNumber,
          birthdate: requestData.dateOfBirth
        }
      }
    })
      .then((response) => {
        if (response.data) {
          setSuccessfulMatch(true);
          responseHandler(response);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          const errorResponseStatus = error.response.status;
          setPageLevelError(errorResponseStatus);

          if (error.response.data && error.response.data.errors) {
            const theseErrors = JSON.stringify(error.response.data.errors);
            if (errorResponseStatus === 404) {
              if (theseErrors.indexOf("No client found.") > 1) {
                setNoClient(true);
                navigate(staticAppConfig.routes.landing);
              }

              if (theseErrors.indexOf("More than one client found.") > 1) {
                setTooManyClients(true);
                navigate(staticAppConfig.routes.error);
              }

              if (theseErrors.indexOf("No client(s) found.") > 1) {
                EnsightenAnalytics.pushScreenChangeToAnalytics("no-email-found");
                //navigate(staticAppConfig.routes.landing);
              }

              if (theseErrors.indexOf("No ID card(s) found.") > 1) {
                navigate(staticAppConfig.routes.noCards);
              }
            } else if (errorResponseStatus === 500) {
              navigate(staticAppConfig.routes.landing);
            }
          }
        } else {
          setPageLevelError(500);
          navigate(staticAppConfig.routes.landing);
        }
      })
      .finally(() => {
        setWaitingForResponse(false);
      });
  } else {
    setPageLevelError(0);
    setWaitingForResponse(false);
  }
};

export const postSelection = async (setWaitingForResponse, setPageLevelError, setEmail, setEmailSent, navigate, liteAuthToken, setHasAccount) => {

  setWaitingForResponse(true);

  axiosInstance({
    method: "post",
    url: getProdSelectEndpoint(),
    data: {
      data: {
        selectedIdCards: "all"
      }
    },
    headers: {
      liteauthtoken: liteAuthToken
    }
  })
    .then((response) => {
      let email = response.data.data.attributes.email;
      let hasAccount = response.data.data.attributes.registeredUser;

      setHasAccount(hasAccount);

      if (email == null) {
        setPageLevelError(404);
      } else {
        setEmail(email);
        setEmailSent(true);
        setWaitingForResponse(false);
      }
    })
    .catch((error) => {
      console.error(error);
      navigate(staticAppConfig.routes.error);
    })
};