import React, { useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";

const oneXTypeClasses = {
  primary: "-oneX-btn-primary",
  secondary: "-oneX-btn-secondary"
}

const OneXButton = ({ id, children, type, clickHandler, disabled, className }) => {

  const wrapperRef = useRef(null); //

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }

  }, []);

  return (
    <div className="-oneX" id={`${id}-wrapper`} ref={wrapperRef}>
      <button
        type={"button"}
        onMouseDown={clickHandler}
        disabled={disabled}
        className={`${oneXTypeClasses[type]} ${className}`.trim()}
      >
        {children}
      </button>
    </div>);
}

export default OneXButton;

OneXButton.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(oneXTypeClasses)).isRequired,
  clickHandler: PropTypes.func.isRequired, 
  disabled: PropTypes.bool,
  className: PropTypes.string
};

OneXButton.defaultProps = {
  children: "Please provide button text in react component.",
  type: "primary",
  clickHandler: () => console.log('Please provide a click handler in react component.'),
  disabled: false
}