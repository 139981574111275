import React, { useLayoutEffect, useState, useRef } from "react";
import FormValidator from "../Utility/FormValidator";
import { useRecoilState } from "recoil";
import { telephoneNumberState, birthdateState } from "../AppData/Recoil";

const OneXTextField = ({
  id,
  label = "Label",
  disabled = false,
  readOnly = false,
  width,
  offset,
  defaultValue,
  dataFormatter,
  changeHandler,
  errorHandler,
  errors = [],
  showError,
  maskTeaLeaf = false,
  autocomplete = true,
  arialabel,
}) => {
  const [value, setValue] = useState(defaultValue);

  const wrapperRef = useRef();

  useLayoutEffect(() => {

    const currentRef = wrapperRef.current;

    window.oneX.addElement(currentRef);

    return () => {
      window.oneX.removeElement(currentRef);
    }
  }, []);

  return (
    <div id={`${id}-wrapper`} ref={wrapperRef} className={`${width} ${offset} ${showError > -1 ? ` -oneX-widget--err` : null}`.trim()} aria-live="assertive">
      <fieldset className="-oneX-fieldset" id={`${id}-fieldset`}>
        <label
          id={`${id}-label`}
          htmlFor={`${id}-input`}
          className="-oneX-textfield__floatingLabel"

        >
          {label}
        </label>
        <input
          id={`${id}-input`}
          autoComplete={autocomplete}
          type={"tel"}
          className={maskTeaLeaf ? "-oneX-textfield__input .tlPrivate" : "-oneX-textfield__input"}
          data-formatter={dataFormatter}
          disabled={disabled}
          readOnly={readOnly}
          aria-label={arialabel}
          aria-invalid={showError > -1 ? true : false}
          aria-describedby={showError > -1 ? `${id}-error` : null}
          onBlur={(e) => {
            e.preventDefault();
            setValue(e.target.value);
            if (changeHandler) {
              changeHandler(e);
            }
            if (errorHandler) {
              errorHandler(e);

            }
          }}
          onChange={(e) => {
            e.preventDefault();
            setValue(e.target.value);
            if (changeHandler) {
              changeHandler(e);
            }
          }}
          defaultValue={value}
        />
      </fieldset>
      {showError > -1 ? (
        <div id={`${id}-error`} className="-oneX-widget__err-text" role="alert" aria-live="assertive" tabindex="-1">
          <span aria-label="Error"> {errors[showError]} </span>
        </div>
      ) : null}
    </div>
  );
};

export const TelephoneTextField = ({
  showError,
  setShowError
}) => {
  const [telephoneNumber, setTelephoneNumber] = useRecoilState(telephoneNumberState);
  return (
    <>
      <OneXTextField
        id={"telephoneField"}
        label={"Phone number"}
        width={"-oneX-col-lg-6"}
        dataFormatter={"phone"}
        defaultValue={telephoneNumber.value}
        changeHandler={(e) => {
          let update = { value: e.target.value === "(" ? "" : e.target.value, validationCode: telephoneNumber.validationCode };
          setTelephoneNumber(update);
        }}
        errors={["Enter a valid phone number."]}
        aria-label="Error"
        showError={showError}
        errorHandler={(e) => {
          let update = { value: telephoneNumber.value, validationCode: FormValidator.validateTelephoneNumber(e.target.value) };
          setShowError(FormValidator.validateTelephoneNumber(e.target.value))
          setTelephoneNumber(update);
        }}
        aria-live={showError ? "assertive" : undefined}
      />
    </>
  );
};

export const BirthdateTextField = ({
  showError,
  setShowError
}) => {
  const [birthdate, setBirthdate] = useRecoilState(birthdateState);

  return (
    <>
      <OneXTextField
        id={"birthdateField"}
        label={"Date of birth (MM-DD-YYYY)"}
        width={"-oneX-col-lg-6"}
        dataFormatter={"date"}
        defaultValue={birthdate.value}
        showError={showError}
        changeHandler={(e) => {
          let update = { value: e.target.value === "(" ? "" : e.target.value, validationCode: birthdate.validationCode };
          setBirthdate(update);
        }}
        errors={["Enter a valid birthdate."]}
        errorHandler={(e) => {
          let update = { value: birthdate.value, validationCode: FormValidator.validateDateOfBirthField(e.target.value) };
          setShowError(FormValidator.validateDateOfBirthField(e.target.value))
          setBirthdate(update);
        }}
        maskTeaLeaf
        aria-live={showError ? "assertive" : undefined}
      />
    </>
  );
};

export const TextFieldSpacing = () => {
  return (
    <div style={{ marginLeft: "1px", marginBottom: "16px" }}></div>
  )
}

export default OneXTextField;
