import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import OneXButton from "../OneX/OneXButton";
import { TelephoneTextField, BirthdateTextField, TextFieldSpacing } from "../OneX/OneXTextField";
import OneXPageLevelNotification from "../OneX/OneXPageLevelNotification";
import OneXContextualHelp from "../OneX/OneXContextualHelp";
import OneXPageLoader from "../OneX/OneXPageLoader";
import Flex from "../LAUI/Flex";
import { postMatch } from "../Utility/API";
import EnsightenAnalytics from "../Utility/EnsightenAnalytics";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  submitLimitState,
  disableButtonState,
  pageLevelErrorState,
  birthdateState,
  telephoneNumberState,
  waitingForResponseState,
  noClientState,
  tooManyClientsState,
  emailState,
  successfulMatchState,
  staticAppConfig,
  jwtState,
} from "../AppData/Recoil";
import { useNavigate } from "react-router-dom";
import FormValidator from "../Utility/FormValidator";
import OneXSpecs from "../OneX/OneXSpecs";

const GetIdCard = () => {
  const isMobile = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.MOBILE);
  const isTablet = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.TABLET);
  const isDesktop = useMediaQuery(OneXSpecs.DEVICE_MEDIA_QUERIES.DESKTOP);

  const errorMessages = [
    `Sorry, but we couldn’t find a policy with that information. Please check it and try again.`,
    `We’re very sorry, but we still can’t find a policy with that information. Call us at 888-559-1922 and we should be able to help.`,
    `We’re very sorry, but we can’t find a policy with that information. Call us at 888-559-1922 and we should be able to help.`,
  ];
  const [init, setInit] = useState(false);
  const birthdate = useRecoilValue(birthdateState);
  const telephoneNumber = useRecoilValue(telephoneNumberState);
  const waitingForResponse = useRecoilValue(waitingForResponseState);
  const [submitLimit, setSubmitLimit] = useRecoilState(submitLimitState);
  const [disableButton, setDisableButton] = useRecoilState(disableButtonState);
  const [pageLevelError, setPageLevelError] = useRecoilState(pageLevelErrorState);
  const [showErrorPhone, setShowErrorPhone] = useState(-1);
  const [showErrorDob, setShowErrorDob] = useState(-1);
  const setSuccessfulMatch = useSetRecoilState(successfulMatchState);
  const setNoClient = useSetRecoilState(noClientState);
  const setTooManyClients = useSetRecoilState(tooManyClientsState);
  const setJwt = useSetRecoilState(jwtState);
  const setWaitingForResponse = useSetRecoilState(waitingForResponseState);
  const setEmail = useSetRecoilState(emailState);
  const formRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!init) {
      EnsightenAnalytics.pushScreenChangeToAnalytics("getIdCardsScreen");
      document.title = "Get insurance ID card - Get insurance ID card";
      console.log("initiated");
      setInit(true);
    }
  }, [init]);

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const responseHandler = (response) => {
    setJwt(response.headers["liteauthtoken"]);

    if (response.data) {
      let agreements = response.data.included;
      let email = response.data.data.attributes.email;

      if (agreements instanceof Array && agreements.length > 0) {
        if (email == null) {
          setPageLevelError(404);
          navigate(staticAppConfig.routes.login);
        } else {
          setEmail(email);
          navigate(staticAppConfig.routes.cardsFound);
        }
      } else {
        navigate(staticAppConfig.routes.noCards);
      }
    }
  };

  const handleClick = () => {
    var thisSubmitLimit = submitLimit - 1;
    setSubmitLimit(thisSubmitLimit);

    if (thisSubmitLimit <= 0) {
      setDisableButton(true);
    }

    const dobCode = FormValidator.validateRequiredField(birthdate.value);
    const phoneNumberCode = FormValidator.validateRequiredField(telephoneNumber.value);
    const ageRequirementCode = FormValidator.validateAgeRequirement(birthdate.value);

    if (ageRequirementCode === 501) {
      setPageLevelError(501);
    } else if (dobCode === -1 && phoneNumberCode === -1) {
      setPageLevelError(-1);
      postMatch(
        telephoneNumber.value,
        birthdate.value,
        setNoClient,
        setTooManyClients,
        setWaitingForResponse,
        setPageLevelError,
        setSuccessfulMatch,
        responseHandler,
        navigate
      );
    } else {
      setShowErrorDob(dobCode);
      setShowErrorPhone(phoneNumberCode);
    }
  };

  return !waitingForResponse ? (
    <div
      className={"-oneX-col-lg-6 -oneX-col-md-8 -oneX-col-sm-10"}
      style={{
        marginBottom: "128px",
        padding: isMobile ? "0px" : null,
      }}
      onKeyPress={handleEnterKey} // TODO depracated function look into replacement
      ref={formRef}
    >
      <h1 style={{ marginTop: isDesktop ? "56px" : isTablet ? "48px" : isMobile ? "40px" : "40px" }}>{"Get insurance ID card"}</h1>

      {pageLevelError > -1 ? (
        <div style={{ marginTop: "53px", marginBottom: "24px" }} role="alert" aria-live="assertive" tabindex="-1">
          <OneXPageLevelNotification id={"PLN_Test"} type={"error"} closeBool={false}>
            {submitLimit <= 0 ? errorMessages[1] : pageLevelError === 501 ? errorMessages[2] : errorMessages[0]}
          </OneXPageLevelNotification>
        </div>
      ) : null}

      {pageLevelError > -1 ? (
        <div className={"-oneX-body--intro"} style={{ marginTop: "24px", marginBottom: "16px" }}>
          <p>
            Provide policyholder's information to get your State Farm<sup>&reg;</sup> Auto ID card.
          </p>
        </div>
      ) : (
        <div className={"-oneX-body--intro"} style={{ marginTop: "48px", marginBottom: "32px" }}>
          <p>
            Provide policyholder's information to get your State Farm<sup>&reg;</sup> Auto ID card.
          </p>
        </div>
      )}

      <div id="contextual-help" style={{ marginBottom: "10px", display: "flex", flexWrap: "nowrap" }}>
        <h7 style={{ marginBottom: "0px", marginTop: "3px" }}>Can I use my card as proof of insurance?</h7>
        <OneXContextualHelp
          id={"contextual-help-message"}
          message={"In most states, law enforcement and car dealerships will accept your paper or digital ID card as proof of insurance."}
          standAlone={true}
          inline={true}
          helpFor="contextual-help"
        />
      </div>

      <p style={{ fontSize: "12px", lineHeight: "18px" }}>
        To get your State Farm Classic+<sup style={{ fontSize: "7px" }}>TM</sup> ID card, please{" "}
        <a href="https://proofing.statefarm.com/login-interceptor/login" style={{ textDecoration: "none", color: "red" }}>
          log in
        </a>{" "}
        and we'll redirect you to Hagerty<sup>&reg;</sup>. Or, contact your agent.
      </p>

      {pageLevelError > -1 ? (
        <div className="-oneX-row" style={{ marginTop: "14px", marginBottom: "48px" }}>
          <TelephoneTextField
            showError={showErrorPhone}
            setShowError={setShowErrorPhone}
            aria-label="Telephone"
            aria-invalid={showErrorPhone ? "true" : "false"}
            aria-describedby={showErrorPhone ? "telephone-error" : null}
          />
          {isMobile || isTablet ? <TextFieldSpacing /> : null}
          <BirthdateTextField showError={showErrorDob} setShowError={setShowErrorDob} />
        </div>
      ) : (
        <div className="-oneX-row" style={{ marginTop: "32px", marginBottom: "48px" }}>
          <TelephoneTextField showError={showErrorPhone} setShowError={setShowErrorPhone} />
          {isMobile || isTablet ? <TextFieldSpacing /> : null}
          <BirthdateTextField showError={showErrorDob} setShowError={setShowErrorDob} />
        </div>
      )}

      <OneXButton id={`auth-primary-button`} type={"primary"} clickHandler={handleClick} disabled={disableButton}>
        Continue
      </OneXButton>
      <div aria-live="assertive" style={{ position: "absolute", left: "-9999px", height: "1px", overflow: "hidden" }}>
        {pageLevelError > -1 ? (submitLimit <= 0 ? errorMessages[1] : pageLevelError === 501 ? errorMessages[2] : errorMessages[0]) : ""}
      </div>
    </div>
  ) : (
    <Flex style={{ marginTop: "105px", marginBottom: "105px" }}>
      <OneXPageLoader />
    </Flex>
  );
};
export default GetIdCard;
